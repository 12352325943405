
.magic-barrier .laser-barrier {
    stroke: rgba(204, 102, 255,1);
    position: absolute;

    &.red {
        stroke: rgba(236,19,65,0.6);
    }

    &.blue {
        stroke: rgba(99,195,231,0.6);
    }

    &.yellow {
        stroke: rgba(255, 237, 74, 0.6);
    }
}

.magic-barrier .barrier-holder {
    position: absolute;
}

.magic-barrier .barrier-strength .circle {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:25px;
    color:#000;
    line-height:50px;
    text-align:center;
    background:#8888;

    position: absolute;

    &.red {
        background: rgba(236,19,65,0.6);
    }

    &.blue {
        background: rgba(99,195,231,0.6);
    }

    &.yellow {
        background: rgba(255, 237, 74, 0.6);
    }
}
