.puzzle-title {
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    font-weight: bold;
}

.desktop .puzzle-title {
  font-size: 50px;
}

.mobile .puzzle-title {
  font-size: 36px;
  padding-top: 10px;
}

.standard-btn {
    padding: 12px 18px;
    margin: 12px auto 0;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background-color: #3498db;
    border-radius: 4px;
  }

.standard-btn:focus {
    border-color: #3498db;
    box-shadow: 0 0 2px #3498db;
    outline: 0;
  }

.standard-btn:hover {
    background-color: #2980b9;
  }
  