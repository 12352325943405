.interactable-button-display .circle {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:25px;
    text-align:center;
    align-items: center;
    border: none;
    background-color:#0a0a23;
    color: #fff;
    justify-content: center;
    display: flex;
}

.interactable-button-display .number {
    line-height:50px;
}

.interactable-button-display .circle:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #01a791;
}

.interactable-button-display .circle-clicked {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:25px;
    line-height:50px;
    text-align:center;
    align-items: center;
    border: none;
    background-color:#262675;
    color: #fff;
    justify-content: center;
    display: flex;
}
.interactable-button-display .circle:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #01a791;
}

.interactable-button-display .circle-disabled {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:25px;
    line-height:50px;
    text-align:center;
    align-items: center;
    border: none;
    background-color:#8383ff;
    color: #fff;
    justify-content: center;
    display: flex;
}



.interactable-button-display .undo-button {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:40px;
    text-align:center;
    align-items: center;
    border: none;
    background-color:#0a0a23;
    color: #fff;
    padding: 0px;
}

.interactable-button-display .undo-button:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #01a791;
}

.interactable-button-display .undo-button:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 4px #01a791;

}

.interactable-button-display .undo-symbol {
    position: relative;
    transform: translate(2px,-2.5px);
}

.interactable-button-display .times-symbol {
    position: relative;
    transform: translate(0.5px, 3px);
}
.interactable-button-display .addition-symbol {
    position: relative;
    transform: translate(0px,3px);
}
.interactable-button-display .subtraction-symbol {
    position: relative;
    transform: translate(0px,3px);
}

.interactable-button-display .division-symbol {
    position: relative;
    transform: translate(0px,3px);
}