.puzzle-table-wrapper {
  padding: 16px;
  margin: 64px auto 0;
  max-width: 768px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  position:absolute;
}

.puzzle-table-wrapper .puzzle-table-form {
  margin-bottom: 32px;
}

.puzzle-table-wrapper .form-row {
  display: flex;
  flex-wrap: wrap;
}

.puzzle-table-wrapper .form-row + .form-row {
  margin-top: 18px;
}

.puzzle-table-wrapper .form-wrapper fieldset {
  padding: 0;
  margin: 0;
  width: 50%;
  border: 0;
}

.puzzle-table-wrapper .form-wrapper fieldset:first-child {
  padding-right: 4px;
}

.puzzle-table-wrapper .form-wrapper fieldset:last-child {
  padding-left: 4px;
}

.puzzle-table-wrapper .form-label {
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  color: #2c3e50;
}

.puzzle-table-wrapper .form-input {
  padding: 8px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #dedede;
  border-radius: 4px;
  transition: border-color .25s ease-out;
}

.puzzle-table-wrapper .btn,
.puzzle-table-wrapper .btn-remove,
.puzzle-table-wrapper .btn-reset {
  display: block;
  border: 0;
  cursor: pointer;
  transition: all .25s ease-out;
}


.puzzle-table-wrapper .btn-remove,
.puzzle-table-wrapper .btn-reset {
  padding: 0;
  font-size: 12px;
  font-style: italic;
  color: hsl(6, 63%, 46%);
  background-color: transparent;
}

.puzzle-table-wrapper .btn-remove:hover,
.puzzle-table-wrapper .btn-reset:hover {
  color: hsl(6, 63%, 26%);
}

.puzzle-table-wrapper .btn-reset {
  margin: 21px auto 0;
}