/* Puzzle list/table */
.puzzle-list .table {
  width: 100%;
  border-collapse: collapse;
}

.puzzle-list .table-body {
  background: rgb(219, 233, 250) none repeat scroll 0 0;
}

.puzzle-list .table-head-item {
  padding: 6px 12px;
  width: 20%;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, .38);
  text-align: center;
}

.puzzle-list .table-row:nth-child(even) {
  background: rgb(236, 243, 252) none repeat scroll 0 0;
}

.puzzle-list .table-item {
  overflow: hidden;
  padding: 12px;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  text-overflow: ellipsis;
}

.puzzle-list .table-head-item:not(:first-child):not(:last-child),
.puzzle-list .table-item:not(:first-child):not(:last-child) {
  text-align: left;
}

.puzzle-list .table-item:first-child {
  width: 5%;
}

.puzzle-list .table-item:nth-child(2) {
  width: 30%;
}

.puzzle-list .table-item:nth-child(3) {
  width: 25%;
}

.puzzle-list .table-item:nth-child(4) {
  width: 10%;
}

.puzzle-list .table-item:nth-child(5) {
  width: 10%;
}

.puzzle-list .table-item:last-child {
  width: 10%;
  min-width: 100px;
}

.puzzle-list .table-row--active {
  font-size: 30px;
  color: #fff;
  background: #4990e2;
}