.current-value-display .circle {
    width:50px;
    height:50px;
    border-radius:50%;
    font-size:25px;
    color:#000;
    line-height:50px;
    text-align:center;
    background:#8888;

    position: absolute;
}
