.interaction-panel-display {
    position: absolute;
    margin: 0 auto;
}

.interaction-panel-display .interaction-table td {
    height: 50px;
    width: 50px;
}

.interaction-panel-display .interaction-table {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.interaction-panel-display .interaction-options {
    display: grid;
}

.interaction-panel-display .interaction-operators {
    display: grid;
}


.interaction-panel-display .btn-panel .btn {
    margin-right: 5px;
}