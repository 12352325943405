.text-feedback {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.error {
        color: red;
        font-weight: bold;
    }
}
