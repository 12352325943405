.navbar {
    position: absolute;
    top: 5px;
    right: 5px;
}

.mobile .navbar {
    padding-top: 10px;
}

.help-modal .dialog {
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
    position: absolute;
}

.help-modal .dialog::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.help-modal .dialog-close-btn {
    font-size: 1.25em;
    position: absolute;
    top: .75em;
    right: .75em;
    font-weight: bold;
}

.results-modal .dialog {
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
    position: absolute;
}

.results-modal .results-content {
    margin: auto;
    width: 50%;
    text-align: center;
}

.results-modal .steps-content h1 {
    margin: auto;
    width: 50%;
    text-align: center;
}

.results-modal .steps-content .steps-display {
    margin: auto;
    width: 50%;
    text-align: center;
}

.results-modal .results-display {
    margin: auto;
    width: 50%;
    text-align: center;
}

.results-modal .share-content standard-btn {
    margin: auto;
    width: 50%;
    text-align: center;
}

.results-modal .dialog::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.results-modal .dialog-close-btn {
    font-size: 1.25em;
    position: absolute;
    top: .75em;
    right: .75em;
    font-weight: bold;
}

.dialog-close-btn {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
}


.dropbtn {
    color: black;
    border: none;
    cursor: pointer;
    background: #dfdede;
}
.desktop .dropbtn {
    font-size: 50px;
}
  
.mobile .dropbtn {
    font-size: 30px;
}

.dropdown .dropdown-icon {
    position: relative;
}
.desktop .dropdown .dropdown-icon {
    transform: translateY(-10px);
}

.dropdown-content {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: center;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1;}
