/* General styles */
html,
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  touch-action: none;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  background: #dfdede;
  font: 1rem / 1.414 -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #333;
}

.title-font {
  font-family: monaco, "Courier New", monospace;
}