
.magic-beam .laser-beam {
    position: absolute;
    height: 5px;
    background: rgba(204, 102, 255, 0.6); 
    -webkit-animation: laser 7.5s infinite;
    animation: laser 7.5s infinite;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(204, 102, 255,1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(204, 102, 255,1);
    box-shadow: 0px 0px 15px 0px rgba(204, 102, 255,1);

    &.red {
        background: rgba(236,19,65,0.6);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(236,19,65,1);
        -moz-box-shadow: 0px 0px 15px 0px rgba(236,19,65,1);
        box-shadow: 0px 0px 15px 0px rgba(236,19,65,1);
    }

    &.blue {
        background: rgba(99,195,231,0.6);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(99,195,231,1);
        -moz-box-shadow: 0px 0px 15px 0px rgba(99,195,231,1);
        box-shadow: 0px 0px 15px 0px rgba(99,195,231,1);
    }

    &.yellow {
        background: rgba(255, 237, 74, 0.6);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 237, 74,1);
        -moz-box-shadow: 0px 0px 15px 0px rgba(255, 237, 74,1);
        box-shadow: 0px 0px 15px 0px rgba(255, 237, 74,1);
    }
}

.magic-beam .beam-start {
    border:2px solid rgb(92, 30, 122);
    height:50px;
    width:50px;
    border-radius:50%;
    -webkit-border-radius:50%;
    position:absolute;

    &.red {
        border:2px solid rgba(236,19,65,0.6);
    }

    &.blue {
        border:2px solid rgba(99,195,231,0.6);
    }

    &.yellow {
        border:2px solid rgba(255, 237, 74, 0.6);
    }
}