.main-screen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-screen .main-title {
  font-weight: bold;
  position: absolute;

  .fire {
    color: rgba(236,19,65,0.6);
  }

  .water {
    color: rgba(99,195,231,0.6);
  }

  .electric {
    color: rgba(255, 237, 74, 0.6);
  }
}

.mobile {
  .main-screen .main-title{
    font-size: 50px;
  }
  .main-screen .standard-btn {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-screen .options {
    margin-top: 25px;
  }
}

.desktop {
  .main-screen .main-title{
    font-size: 75px;
  }
}
